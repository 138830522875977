import { render, staticRenderFns } from "./SelTree3.vue?vue&type=template&id=6c94872e&scoped=true"
import script from "./SelTree3.vue?vue&type=script&lang=js"
export * from "./SelTree3.vue?vue&type=script&lang=js"
import style0 from "./SelTree3.vue?vue&type=style&index=0&id=6c94872e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c94872e",
  null
  
)

export default component.exports