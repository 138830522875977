<template>
    <div>
        <div class="sel-tree">
            <div class="select-wrapper">
                <div class="title">
                    选择
                </div>
                <div class="wrapper-line">
                    <el-input
                        placeholder="输入关键字进行过滤"
                        v-model="filterText">
                    </el-input>
                    <el-checkbox
                        v-if="showCheckALl"
                        v-model="checkAll"
                        :indeterminate="isIndeterminate"
                        class="check-all"
                        @change="handleCheckAllChange"
                    >全选</el-checkbox>
                    <!-- <el-tree
                        node-key="id"
                        ref="tree"
                        :data="selTreeOpt.data"
                        :show-checkbox="selTreeOpt.status === 'multiple'"
                        :props="defaultProps"
                        @check="handleCheck"
                        @node-click="nodeClick"
                        :filter-node-method="filterNode"
                        :empty-text="selTreeOpt.empty"
                    >
                    </el-tree> -->
                    <vue-easy-tree
                        node-key="id"
                        ref="tree"
                        :data="selTreeOpt.data"
                        :show-checkbox="selTreeOpt.status === 'multiple'"
                        :props="defaultProps"
                        @check="handleCheck"
                        @node-click="nodeClick"
                        :filter-node-method="filterNode"
                        :empty-text="selTreeOpt.empty"
                        height="340px"
                    ></vue-easy-tree>
                </div>
            </div>
            <div class="select-wrapper">
                <div class="title">
                    <span>已选({{ checkedLength() }})</span>
                    <span v-show="Number(selTreeOpt.maxLen) > 0">可选({{ selTreeOpt.maxLen - checkedLength() }})</span>
                </div>
                <div class="wrapper-line">
                    <div
                        v-for="(item, index) of selList"
                        :key="index"
                        class="sel-name"
                    >
                        <span class="name">{{ item.name }}</span>
                        <span class="sel-del el-icon-circle-close" @click="del(index)"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-group">
            <xk-button type="primary" @click="confirm">确定</xk-button>
            <xk-button @click="cancel" style="margin-left: 20px">取消</xk-button>
        </div>
    </div>
</template>
<script>
import VueEasyTree from "@wchbrad/vue-easy-tree";
import "@wchbrad/vue-easy-tree/src/assets/index.scss"
export default {
    name: "SelTree",
    components: {
        VueEasyTree
    },
    props: {
        selTreeOpt: {
            type: Object
        }
    },
    computed: {
        showCheckALl() {
            return this.selTreeOpt.status === 'multiple' && this.filterText === '' && this.selTreeOpt.data && this.selTreeOpt.data.length > 0
        }
    },
    data () {
        return {
            filterText: '',
            selList: [],
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            checkAll: false,
            isIndeterminate: false
        }
    },
    created () {
        this.init()
    },
    methods: {
        init() {
            this.$nextTick(() => {
                this.$refs.tree.setCheckedKeys(this.selTreeOpt.showData)
                if (this.selTreeOpt.status === 'single') {
                    this.selList = this.$refs.tree.getCheckedNodes()
                } else {
                    this.handleCheck()
                }
            })
        },
        nodeClick(data) {
            if (this.selTreeOpt.status === 'single') {
                if (data.key === 'student' || data.key === 'teacher') {
                    this.selList = [data]
                }
            }
            this.$emit('nodeClick', data)
        },
        handleCheck() {
            if (Number(this.selTreeOpt.maxLen) > 0 && this.checkedLength() > this.selTreeOpt.maxLen) {
                this.$refs.tree.setCheckedNodes(this.selList)
                return this.$message.warning(`最多可选${this.selTreeOpt.maxLen}条数据`)
            }
            let data = this.$refs.tree.getCheckedNodes()
            this.selList = data.filter(i => !data.find(it => it.id === i.parentOrg))
            this.changeIsIndeterminate()
        },
        // 选中人员长度
        checkedLength() {
            if (this.$refs.tree) {
                return this.$refs.tree.getCheckedNodes().filter(i => i.key !== 'organ').length
            }
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        // 全选/清空
        handleCheckAllChange(data) {
            if (data) {
                this.$refs.tree.setCheckedNodes(this.selTreeOpt.data)
            } else {
                this.$refs.tree.setCheckedNodes([])
            }
            this.handleCheck()
        },
        // 重置
        resetChecked() {
            this.$refs.tree.setCheckedKeys([]);
            this.filterText = ''
        },
        del(index) {
            this.selList.splice(index, 1);
            this.$refs.tree.setCheckedNodes(this.selList);
            this.changeIsIndeterminate()
        },
        // 修改全选按钮状态
        changeIsIndeterminate() {
            if (this.showCheckALl) {
                this.checkAll = this.selList.length !== 0
                this.isIndeterminate = this.checkAll && this.selList.length !== this.selTreeOpt.data.length
            }
        },
        // 确定
        confirm() {
            if (this.selTreeOpt.status === 'single') {
                this.$emit('setTreeData', {data: this.selList, key: this.selTreeOpt.key})
            } else {
                let names = this.selList.map(i => i.name).toString()
                this.$emit('setTreeData', {data: this.$refs.tree.getCheckedNodes(), selList: names, key: this.selTreeOpt.key})
            }
        },
        // 取消
        cancel() {
            this.$emit('cancel')
        },
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
}
</script>
<style lang="scss" scoped>
    .sel-tree {
        display: flex;
        justify-content: space-between;
        .select-wrapper {
            width: 48%;

            .title {
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                height: 34px;
                line-height: 34px;
            }

            .wrapper-line {
                height: 400px;
                overflow: auto;
                border: 1px solid #F4F4F4;

                .check-all {
                    margin-left: 24px;
                }

                .sel-name {
                    height: 30px;
                    line-height: 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    box-sizing: border-box;
                    padding: 0 40px;
                    border-bottom: 1px solid #f3f3f3;
                    .name {
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .sel-del {
                        font-size: 16px;
                        cursor: pointer;
                    }
                }
            }

        }
    }
    .btn-group {
        padding-top: 20px;
        text-align: center;
    }
</style>
