<template>
    <div class="sel-tree">
        <div class="select-wrapper">
            <div class="title">
                选择
            </div>
            <div class="wrapper-line">
                <el-input
                    placeholder="输入关键字进行过滤"
                    v-model="filterText">
                </el-input>
                <el-checkbox
                    v-if="selTreeOpt.status === 'multiple' && !selTreeOpt.checkStrictly && filterText === ''"
                    v-model="checkAll"
                    :indeterminate="isIndeterminate"
                    class="check-all"
                    @change="handleCheckAllChange"
                >全选</el-checkbox>
                <el-tree
                    :data="selTreeOpt.data"
                    show-checkbox
                    node-key="id"
                    ref="tree"
                    :check-strictly="selTreeOpt.checkStrictly"
                    :props="defaultProps"
                    @check="handleClick"
                    @node-click="nodeClick"
                    :filter-node-method="filterNode"
                    :empty-text="selTreeOpt.empty"
                    >
                </el-tree>
                   <!--  -->
            </div>
        </div>
        <div class="select-wrapper">
            <div class="title">
                已选
            </div>
            <div class="wrapper-line">
                <div
                    v-for="(item, index) of selList"
                    :key="index"
                    class="sel-name"
                >
                    <span class="name">{{ item.name }}</span>
                    <span class="sel-del el-icon-circle-close" @click="del(item, index)"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SelTree",
    props: {
        selTreeOpt: {
            type: Object
        }
    },
    data () {
        return {
            filterText: '',
            editChecked: '',
            treeData: [],
            selList: [],
            handleClickStatus: true,
            checkAll: false,
            isIndeterminate: false,
            defaultProps: {
                children: 'children',
                label: 'name'
            },
        }
    },
    created () {
        this.init()
    },
    methods: {
        init () {
            this.$nextTick(() => {
                this.filterText = '';
                console.log(this.selTreeOpt.showData,'this.selTreeOpt.showData1234')
                this.$refs.tree.setCheckedKeys(this.selTreeOpt.showData);
                if (!this.selTreeOpt.checkStrictly) {
                    this.checkStrictlyClick()
                } else {
                    this.treeData = this.$refs.tree.getCheckedNodes()
                    this.selList = []
                }
            })
        },
        nodeClick (data, node ,v) {
            if (this.selTreeOpt.checkStrictly) {
                this.$refs.tree.setCheckedNodes([data]);
            }
        },
        handleClick(data,checked, node){
            console.log('0000-----00000')
            if (this.selTreeOpt.checkStrictly) {

                if (this.selTreeOpt.status == 'single') {

                    if(checked){
                        this.$refs.tree.setCheckedNodes([data]);
                        this.selList.splice(0, 1);
                        this.selList.push(data);
                        this.$emit('setTreeData', {data: data, key: this.selTreeOpt.key, id: this.selTreeOpt.id, typeId: this.selTreeOpt.typeId,index: this.selTreeOpt.index})
                    } else {
                        this.selList.forEach((item, index) => {
                            if (item.id == data.id) {
                                this.selList.splice(index, 1);
                            }
                        })

                        if (this.selList.length == 0) {
                            this.$emit('setTreeData', {data: {}, key: this.selTreeOpt.key})
                        }
                    }
                } else {
                    if ( !this.handleClickStatus ) return;
                    // 多选
                    // 是否限制最大数量
                    if (this.selTreeOpt.maxLen && this.treeData.length >= Number(this.selTreeOpt.maxLen) && checked) {
                        this.$message.error(`最多选择${this.selTreeOpt.maxLen}个,如想选择请删除`);
                        this.setNewSel()
                        return;
                    }

                    let contains = false,
                        index = 0;
                    this.treeData.forEach((item, idx) => {
                        if (item.id == data.id) {
                            contains = true;
                            index = idx;
                        }
                    });

                    contains ? this.treeData.splice(index, 1) : this.treeData.push(data);
                    this.selList = JSON.parse(JSON.stringify(this.treeData));
                    this.$emit('setTreeData', {data: this.treeData, key: this.selTreeOpt.key,selList: this.treeData.map(i => i.name), id: this.selTreeOpt.id, typeId: this.selTreeOpt.typeId,index: this.selTreeOpt.index});
                }
            } else {
                this.checkStrictlyClick()
            }
        },
        setNewSel () {
            this.handleClickStatus = false;
            this.$refs.tree.setCheckedNodes(this.selList);
            setTimeout(() => {
                this.handleClickStatus = true;
            }, 20)
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        handleCheckAllChange(data) {
            if (data) {
                // 全选
                this.$refs.tree.setCheckedNodes(this.selTreeOpt.data)
            } else {
                // 清空
                this.$refs.tree.setCheckedNodes([])
            }
            this.checkStrictlyClick()
            // 修改全选框状态
            this.changeIsIndeterminate()
        },
        checkStrictlyClick() {
            this.treeData = this.$refs.tree.getCheckedNodes()
            let halfCheckedNodes = this.$refs.tree.getHalfCheckedNodes()
            this.selList = []
            this.treeData.forEach((item) => {
                if (item.key !== 'organ' && halfCheckedNodes.find(i => i.id === item.parentOrg)) {
                    this.selList.push(item)
                }
                if (item.key === 'organ' && !this.treeData.find(i => i.id === item.parentOrg)) {
                    this.selList.push(item)
                }
            })
            this.$emit('setTreeData', {data: this.treeData, selList: this.selList.map(i => i.name), key: this.selTreeOpt.key,id: this.selTreeOpt.id, typeId: this.selTreeOpt.typeId,index: this.selTreeOpt.index});
        },
        changeIsIndeterminate() {
            if (this.selTreeOpt.data.length === this.selList.length) {
                this.checkAll = true
            } else {
                if (this.selList.length === 0) {
                    this.checkAll = false
                    this.isIndeterminate = false
                } else {
                    this.isIndeterminate = true
                }
            }
        },
        resetChecked() {
            this.$refs.tree.setCheckedKeys([]);
            if (!this.selTreeOpt.checkStrictly) {
                this.checkStrictlyClick()
            }
            this.filterText = ''
        },
        del (data, index) {
            if (this.selTreeOpt.status == 'single') {
                this.selList = [];
                this.$refs.tree.setCheckedNodes([data.id]);
                console.log('ssss')
                this.$emit('setTreeData', {data: {}, key: this.selTreeOpt.key,id: this.selTreeOpt.id, typeId: this.selTreeOpt.typeId,index: this.selTreeOpt.index})
            } else {

                this.handleClickStatus = false;
                this.selList.splice(index, 1);
                this.treeData = this.$refs.tree.getCheckedNodes();
                console.log(this.treeData,this.selList,'this.treeData---')
                this.$emit('setTreeData', {data: this.selList, selList: this.selList.map(i => i.name), key: this.selTreeOpt.key,id: this.selTreeOpt.id, typeId: this.selTreeOpt.typeId,index: this.selTreeOpt.index});
                this.setNewSel();
                setTimeout(() => {
                    this.handleClickStatus = true;
                }, 20)
            }
            this.changeIsIndeterminate()
        },
        /*
            全选
        */
        setCheckedNodes () {
            this.$refs.tree.setCheckedNodes(this.selTreeOpt.data)
        },
        //
    },
    watch: {
        'selTreeOpt.showData': {
            handler() {
                this.init()
            },
            deep: true
        },
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },

}
</script>
<style lang="scss" scoped>
    .sel-tree {
        display: flex;
        justify-content: space-between;
        .select-wrapper {
            width: 48%;

            .title {
                height: 34px;
                line-height: 34px;
                font-size: 16px;
            }

            .wrapper-line {
                height: 400px;
                overflow: auto;
                border: 1px solid #F4F4F4;

                .check-all {
                    margin-left: 24px;
                }

                .sel-name {
                    height: 30px;
                    line-height: 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    box-sizing: border-box;
                    padding: 0 40px;
                    border-bottom: 1px solid #f3f3f3;
                    .name {
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .sel-del {
                        font-size: 16px;
                        cursor: pointer;
                    }
                }
            }

        }
    }
</style>
