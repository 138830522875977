<template>
    <div>
        <div class="sel-tree">
            <div class="select-wrapper">
                <div class="title">
                    选择
                </div>
                <div class="wrapper-line">
                    <el-input
                        placeholder="输入关键字进行过滤"
                        v-model="filterText">
                    </el-input>
                    <el-checkbox
                        v-if="showCheckALl"
                        v-model="checkAll"
                        :indeterminate="isIndeterminate"
                        class="check-all"
                        @change="handleCheckAllChange"
                    >全选</el-checkbox>
                    <vue-easy-tree
                        node-key="nodeId"
                        ref="tree"
                        :data="selTreeOpt.data"
                        :show-checkbox="selTreeOpt.status === 'multiple'"
                        :props="defaultProps"
                        @check="handleCheck"
                        @node-click="nodeClick"
                        :filter-node-method="filterNode"
                        :empty-text="selTreeOpt.empty"
                        height="340px"
                    >
                        <span class="custom-tree-node" slot-scope="{ node, data }">
                            <div v-if="node.label.length < 10">{{ node.label }}</div>
                            <el-tooltip v-else class="item" effect="dark" :content="node.label" placement="top-start">
                                <span class="tree-label"> {{ node.label }} </span>
                            </el-tooltip>
                        </span>
                    </vue-easy-tree>
                </div>
            </div>
            <div class="select-wrapper">
                <div class="title">
                    <span>已选({{ checkedLength() }})</span>
                    <span v-show="Number(selTreeOpt.maxLen) > 0">可选({{ selTreeOpt.maxLen - checkedLength() }})</span>
                </div>
                <div class="wrapper-line">
                    <div
                        v-for="(item, index) of selList"
                        :key="index"
                        class="sel-name"
                    >
                        <span class="name">{{ item.name }}</span>
                        <span class="sel-del el-icon-circle-close" @click="del(index)"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-group">
            <el-button @click="cancel">取消</el-button>
            <el-button :loading="loading" type="primary" @click="confirm">确定</el-button>
        </div>
    </div>
</template>

<script>
import VueEasyTree from "@wchbrad/vue-easy-tree";
import "@wchbrad/vue-easy-tree/src/assets/index.scss"
import { treeToList, treeForeach } from "@/libs/utils.js"

export default {
    name: "SelTree",
    components: {
        VueEasyTree
    },
    props: {
        loading: {
            type: Boolean
        },
        selTreeOpt: {
            type: Object
        },
        isLeaf: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        showCheckALl() {
            return this.selTreeOpt.status === 'multiple' && this.filterText === '' && this.selTreeOpt.data && this.selTreeOpt.data.length > 0
        }
    },
    data () {
        return {
            treeList: [],
            filterText: '',
            selList: [],
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            checkAll: false,
            isIndeterminate: false
        }
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    created () {
        this.init()
    },
    methods: {
        init() {
            treeForeach(this.selTreeOpt.data, 'children', node => {
                this.$set(node, 'nodeId', node.parentOrg + node.id)
            })
            this.treeList = treeToList(this._.cloneDeep(this.selTreeOpt.data), 'children')
            this.$nextTick(() => {
                let checkedKeys = []
                treeForeach(this.selTreeOpt.data, 'children', node => {
                    if(this.selTreeOpt.showData.includes(node.id)) {
                        checkedKeys.push(node.nodeId)
                    }
                })
                this.$refs.tree.setCheckedKeys(checkedKeys)

                if (this.selTreeOpt.status === 'single') {
                    let selList = this.$refs.tree.getCheckedNodes(this.isLeaf)
                    this.selList = this.unique(selList, 'id')
                } else {
                    this.handleCheck()
                }
            })
        },
        // 对象数组根据指定字段去重
        unique(arr, val) {
            const res = new Map()
            return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1))
        },
        // 点击节点
        nodeClick(data) {
            if (this.selTreeOpt.status === 'single') {
                if (data.key === 'student' || data.key === 'teacher') {
                    this.selList = [data]
                }
            }
            this.$emit('nodeClick', data)
        },
        // 选中/取消选中节点
        handleCheck() {
            if (Number(this.selTreeOpt.maxLen) > 0 && this.checkedLength() > this.selTreeOpt.maxLen) {
                this.$refs.tree.setCheckedNodes(this.selList)
                return this.$message.warning(`最多可选${this.selTreeOpt.maxLen}条数据`)
            }
            let data = this.$refs.tree.getCheckedNodes(this.isLeaf)
            console.log(data);
            this.selList = data.filter(i => !data.find(it => it.id === i.parentOrg));
            //去重 一个人在多个部门都有职位时会重复
            this.selList = this.unique(this.selList, 'id')
            this.changeIsIndeterminate()
        },
        // 选中人员长度
        checkedLength() {
            return this.selList.length;
        },
        // 重新给树赋选中值
        setChecked() {
            let checkedIds = this.selList.map(x => x.id)
            let checkedKeys = [];
            treeForeach(this.selTreeOpt.data, 'children', node => {
                if(checkedIds.includes(node.id)) {
                    checkedKeys.push(node.nodeId)
                }
            })
            this.$refs.tree.setCheckedKeys(checkedKeys)
        },
        // 搜索
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        // 全选/清空
        handleCheckAllChange(data) {
            if (data) {
                this.$refs.tree.setCheckedNodes(this.selTreeOpt.data)
            } else {
                this.$refs.tree.setCheckedNodes([])
            }
            this.handleCheck()
        },
        // 重置
        resetChecked() {
            this.$refs.tree.setCheckedKeys([]);
            this.filterText = ''
            this.selList = [];
            this.checkAll = false;
            this.isIndeterminate = false;
        },
        // 右侧删除选中节点
        del(index) {
            this.selList.splice(index, 1);
            this.setChecked()
            this.changeIsIndeterminate()
        },
        // 修改全选按钮状态
        changeIsIndeterminate() {
            if (this.showCheckALl) {
                let checkedNodes = this.$refs.tree.getCheckedNodes(this.isLeaf);
                this.checkAll = checkedNodes.length == this.treeList.length;
                this.isIndeterminate = !this.checkAll && this.selList.length > 0;
            }
        },
        // 确定
        confirm() {
            if (this.selTreeOpt.status === 'single') {
                this.$emit('setTreeData', {data: this.selList, key: this.selTreeOpt.key})
            } else {
                let names = this.selList.map(i => i.name).toString()
                this.$emit('setTreeData', {data: this.selList, selList: names, key: this.selTreeOpt.key})
            }
        },
        // 取消
        cancel() {
            this.$emit('cancel')
        },
    }
}
</script>
<style lang="scss" scoped>
    .sel-tree {
        display: flex;
        justify-content: space-between;
        .select-wrapper {
            width: 48%;

            .title {
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                height: 34px;
                line-height: 34px;
            }

            .wrapper-line {
                height: 400px;
                overflow: auto;
                border: 1px solid #F4F4F4;

                .check-all {
                    margin-left: 24px;
                }

                .sel-name {
                    height: 30px;
                    line-height: 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    box-sizing: border-box;
                    padding: 0 40px;
                    border-bottom: 1px solid #f3f3f3;
                    .name {
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .sel-del {
                        font-size: 16px;
                        cursor: pointer;
                    }
                }
            }

        }
    }
    .btn-group {
        padding-top: 20px;
        text-align: right;
    }

    ::v-deep .custom-tree-node {
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>

