<template>
    <div>
        <div class="sel-tree">
            <div class="select-wrapper">
                <div class="title">
                    选择
                </div>
                <div class="wrapper-line">
                    <el-input
                        placeholder="输入关键字进行过滤"
                        v-model="filterText">
                    </el-input>
                    <el-checkbox
                        v-if="showCheckALl"
                        v-model="checkAll"
                        :indeterminate="isIndeterminate"
                        class="check-all"
                        @change="handleCheckAllChange"
                    >全选</el-checkbox>
                    <vue-easy-tree
                        node-key="nodeId"
                        ref="tree"
                        :data="selTreeOpt.data"
                        :show-checkbox="selTreeOpt.status === 'multiple'"
                        :props="defaultProps"
                        @check="handleCheck"
                        @node-click="nodeClick"
                        :filter-node-method="filterNode"
                        :empty-text="selTreeOpt.empty"
                        height="340px"
                    ></vue-easy-tree>
                </div>
            </div>
            <div class="select-wrapper">
                <div class="title">
                    <span>已选({{ checkedLength() }})</span>
                    <span v-show="Number(selTreeOpt.maxLen) > 0">可选({{ selTreeOpt.maxLen - checkedLength() }})</span>
                </div>
                <div class="wrapper-line">
                    <template v-for="(item, index) of selList">
                        <div :key="index" v-if="item.key != 'organ'" class="sel-name">
                            <span class="sel-name-text">{{ item.name }}</span>
                            <span class="sel-del el-icon-circle-close" @click="del(index, item)"></span>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="btn-group">
            <xk-button type="primary" @click="confirm">确定</xk-button>
            <xk-button @click="cancel" style="margin-left: 20px">取消</xk-button>
        </div>
    </div>
</template>

<script>
import VueEasyTree from "@wchbrad/vue-easy-tree";
import "@wchbrad/vue-easy-tree/src/assets/index.scss"
import { treeToList, treeForeach, treeFindPathAll } from "@/libs/utils.js"

export default {
    name: "SelTree",
    components: {
        VueEasyTree
    },
    props: {
        selTreeOpt: {
            type: Object
        }
    },
    computed: {
        showCheckALl() {
            return this.selTreeOpt.status === 'multiple' && this.filterText === '' && this.selTreeOpt.data && this.selTreeOpt.data.length > 0
        }
    },
    data () {
        return {
            treeList: [],
            filterText: '',
            selList: [],
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            checkAll: false,
            isIndeterminate: false
        }
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    created () {
        this.init()
    },
    methods: {
        init() {
            treeForeach(this.selTreeOpt.data, 'children', node => {
                this.$set(node, 'nodeId', node.parentOrg + node.id)
            })
            this.treeList = treeToList(this._.cloneDeep(this.selTreeOpt.data), 'children')
            this.$nextTick(() => {
                let checkedKeys = []
                treeForeach(this.selTreeOpt.data, 'children', node => {
                    if(this.selTreeOpt.showData.includes(node.id)) {
                        checkedKeys.push(node.nodeId)
                    }
                })
                this.$refs.tree.setCheckedKeys(checkedKeys)

                if (this.selTreeOpt.status === 'single') {
                    let selList = this.$refs.tree.getCheckedNodes()
                    this.selList = this.unique(selList, 'id')
                } else {
                    this.handleCheck()
                }
            })
        },
        // 对象数组根据指定字段去重
        unique(arr, val) {
            const res = new Map()
            return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1))
        },
        // 点击节点
        nodeClick(data) {
            if (this.selTreeOpt.status === 'single') {
                if (data.key === 'student' || data.key === 'teacher') {
                    this.selList = [data]
                }
            }
            // console.log("[SelTree][nodeClick]this.selList", this._.cloneDeep(this.selList))
            this.$emit('nodeClick', data)
        },
        // 选中/取消选中节点
        handleCheck(nodeData, checkedData) {
            // console.log("[handleCheck]", nodeData, checkedData)
            if (Number(this.selTreeOpt.maxLen) > 0 && this.checkedLength() > this.selTreeOpt.maxLen) {
                this.$refs.tree.setCheckedNodes(this.selList)
                return this.$message.warning(`最多可选${this.selTreeOpt.maxLen}条数据`)
            }
            let data = this._.cloneDeep(this.$refs.tree.getCheckedNodes())
            // console.log("[SelTree][handleCheck]data all:", this._.cloneDeep(data))
            if(nodeData && nodeData.id) {
                let oldCheckedIds = this._.cloneDeep(this.selList).map(x => x.id)
                if(oldCheckedIds.includes(nodeData.id)) { // 取消勾选
                    let clickNodeIds = [nodeData.id]
                    if(nodeData.children && nodeData.children.length > 0) {
                        let clickNodeChildrenIds = treeToList(this._.cloneDeep(nodeData.children), 'children').map(x => x.id)
                        clickNodeIds.push(...clickNodeChildrenIds)
                    }
                    // console.log("[SelTree][handleCheck]clickNodeIds:", this._.cloneDeep(clickNodeIds))
                    data = data.filter(x => !clickNodeIds.includes(x.id))
                }
            }
            // console.log("[SelTree][handleCheck]data filter:", this._.cloneDeep(data))
            this.selList = this.unique(data, 'id')
            // console.log("[SelTree][handleCheck]this.selList:", this._.cloneDeep(this.selList))
            this.setChecked()
            this.changeIsIndeterminate()
        },
        // 选中人员长度
        checkedLength() {
            return this.selList.filter(item => item.key != 'organ').length
        },
        // 重新给树赋选中值
        setChecked() {
            let checkedIds = this.selList.map(x => x.id)
            let checkedKeys = [];
            treeForeach(this.selTreeOpt.data, 'children', node => {
                if(checkedIds.includes(node.id)) {
                    checkedKeys.push(node.nodeId)
                }
            })
            this.$refs.tree.setCheckedKeys(checkedKeys)
        },
        // 搜索
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        // 全选/清空
        handleCheckAllChange(data) {
            if (data) {
                this.$refs.tree.setCheckedNodes(this.selTreeOpt.data)
            } else {
                this.$refs.tree.setCheckedNodes([])
            }
            this.handleCheck()
        },
        // 重置
        resetChecked() {
            this.$refs.tree.setCheckedKeys([]);
            this.filterText = ''
        },
        // 右侧删除选中节点
        del(index, item) {
            // console.log("[del]item:", item, this.selTreeOpt.data)
            let path = treeFindPathAll(this.selTreeOpt.data, 'children', (node) => node.id == item.id)
            path = path.flat(Infinity)
            path = this.unique(path, 'id').map(x => x.id)
            // console.log("[del]path:", path)
            this.selList = this.selList.filter(x => !path.includes(x.id))
            // console.log("[del]this.selList:", this.selList)
            this.setChecked()
            this.changeIsIndeterminate()
        },
        // 修改全选按钮状态
        changeIsIndeterminate() {
            if (this.showCheckALl) {
                let checkedNodes = this.$refs.tree.getCheckedNodes()
                // checkedNodes = checkedNodes.filter(x => x.key != 'organ')
                // checkedNodes = this.unique(checkedNodes, 'id')
                // console.log("[changeIsIndeterminate]checkedNodes", this._.cloneDeep(checkedNodes), checkedNodes.length == this.treeList.length)
                this.checkAll = checkedNodes.length == this.treeList.length
                this.isIndeterminate = !this.checkAll && this.selList.length > 0
            }
        },
        // 确定
        confirm() {
            let selList = this.selList.filter(x => x.key != 'organ') // 过滤掉组织
            if (this.selTreeOpt.status === 'single') {
                this.$emit('setTreeData', {data: selList, key: this.selTreeOpt.key})
            } else {
                let names = selList.map(i => i.name).toString()
                this.$emit('setTreeData', {data: selList, selList: names, key: this.selTreeOpt.key})
            }
        },
        // 取消
        cancel() {
            this.$emit('cancel')
        },
    }
}
</script>

<style lang="scss" scoped>
    .sel-tree {
        display: flex;
        justify-content: space-between;
        .select-wrapper {
            width: 48%;

            .title {
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                height: 34px;
                line-height: 34px;
            }

            .wrapper-line {
                height: 400px;
                overflow: auto;
                border: 1px solid #F4F4F4;

                .check-all {
                    margin-left: 24px;
                }

                .sel-name {
                    height: 30px;
                    line-height: 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    box-sizing: border-box;
                    padding: 0 40px;
                    border-bottom: 1px solid #f3f3f3;
                    .name {
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .sel-name-text {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-right: 10px;
                    }

                    .sel-del {
                        font-size: 16px;
                        cursor: pointer;
                    }
                }
            }

        }
    }
    .btn-group {
        padding-top: 20px;
        text-align: center;
    }
</style>
